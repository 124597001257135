<template>
  <div class="discussion-board">
    <div class="container">
      <div class="info-section dashboard-panel">
        <div class="_section-head mb-0 pb-0">
          <h5>Recordings</h5>
        </div>
        <div class="_section-body">
          <div class="content">
            <div class="row" v-if="isLoading == true">
              <CircularLoader />
            </div>
            <div class="row" v-else>
              <div
                class="col-md-4"
                v-for="(recording, recordingIndex) in recordings"
                :key="recordingIndex"
              >
                <router-link
                  v-if="recording.status == 'SUCCESS'"
                  :to="{ name: 'recordingVideo', params: { id: recording.id } }"
                >
                  <div class="card module-card">
                    <img
                      v-if="recording.image != null"
                      :src="recording.image"
                    />
                    <img src="/img/module-illus.30122f70.png" v-else />
                    <div class="card-body">
                      <h5 class="card-title">{{ recording.program_title }}</h5>
                      <span class="subject-tag">{{
                        recording.subject_title
                      }}</span>
                      <p class="card-text">
                        <span><i class="far fa-clock"></i></span>
                        {{ recording.start_time }} - {{ recording.end_time }}
                      </p>
                      <a href="#" class="cta-play"
                        ><i class="fas fa-play"></i
                      ></a>
                    </div>
                  </div>
                </router-link>
                <router-link v-else to="#">
                  <div class="card module-card">
                    <img
                      v-if="recording.image != null"
                      :src="recording.image"
                    />
                    <img src="/img/module-illus.30122f70.png" v-else />
                    <div class="card-body">
                      <h5 class="card-title">{{ recording.program_title }}</h5>
                      <span class="subject-tag">{{
                        recording.subject_title
                      }}</span>
                      <p class="card-text">
                        <span><i class="far fa-clock"></i></span>
                        {{ recording.start_time }} - {{ recording.end_time }}
                      </p>
                      <p class="card-text">Recording Processing..</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <EmptyList
              v-if="recordings.length == 0 && isLoading == false"
              messageText="No Recordings!"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircularLoader from "@/components/loaders/CircularLoader";
import CourseService from "@/services/CourseService.js";
import EmptyList from "@/components/EmptyList.vue";

export default {
  name: "Recordings",

  components: { CircularLoader, EmptyList },
  created() {
    this.getRecordings();
  },
  data() {
    return {
      recordings: [],
      scheduleId: this.$route.params.id,
      isLoading: true,
    };
  },
  methods: {
    getRecordings() {
      CourseService.getRecordings({
        schedule_id: this.scheduleId,
      })
        .then((result) => {
          this.recordings = result.data.recordings;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/dashboard.scss";
</style>
